import React, { Component } from 'react';
import { useParams, useLocation, useNavigate } from "react-router-dom";

/*
export class MeetingRedirect extends Component {

    constructor(props) {
        super(props);
        console.log(props);
    }

    componentDidMount() {
        console.log(this.props.uid);
        console.log(this.match);
        console.log(this.location);
        // window.location.href = `https://test.app/api/meetings/${uid}/redirect-to-google-calendar`;
    }

    render() {
        const { uid } = this.props;
        return <div>Redirecting { uid }...</div>;
    }
}
*/

export const MeetingRedirect = (props) => {
    const { uid } = useParams();
    window.location.href = `/api/meetings/${uid}/redirect-to-google-calendar`;

    return null;
}

export default MeetingRedirect;
