import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import { MeetingRedirect } from './components/MeetingRedirect';
import './styles/custom.css';

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Layout>
          <Routes>
            <Route path="/wydarzenie/:uid/google-calendar" element={<MeetingRedirect />} />
            <Route path="/wydarzenia/:uid/google-calendar" element={<MeetingRedirect />} />
            <Route path="/event/:uid/google-calendar" element={<MeetingRedirect />} />
            <Route path="/events/:uid/google-calendar" element={<MeetingRedirect />} />
            {
              AppRoutes.map((route, index) => {
                const { element, ...rest } = route;
                  return <Route key={index} {...rest} element={element} />;
              })
            }
          </Routes>
      </Layout>
    );
  }
}
